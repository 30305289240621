import Axios from "axios";
import { APP } from "../../../config";
import { config } from "./base.service";

export const userService = {
    find,
};

function find(username) {
    return Axios.get(`${APP.END_POINT}/user/${username}`, config());
}
