import {GUI_ACTIONS} from "../actions/gui.actions";

const initialState = {
    extraToolbar: undefined,
    isFilterOpened: false,
    isComposerTabEnabled: false,
};

export function gui(state = initialState, action) {
    switch (action.type) {
        case GUI_ACTIONS.CHANGE_EXTRA_TOOLBAR:
            return {...state, extraToolbar: action.payload};
        case GUI_ACTIONS.CLEAN_EXTRA_TOOLBAR:
            return {...state, extraToolbar: undefined};
        case GUI_ACTIONS.TOGGLE_FILTER_CONTACTS:
            return {...state, isFilterOpened: !state.isFilterOpened};
        case GUI_ACTIONS.TOGGLE_COMPOSER_TAB:
            return {...state, isComposerTabEnabled: !state.isComposerTabEnabled};
        default:
            return state;
    }
}