import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Trilean from "@jetam/core/src/Trilean";
import {IconNames} from "@blueprintjs/icons";
import {Icon} from "@blueprintjs/core";

ColumnSorting.propTypes = {
    name: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    sort: PropTypes.func.isRequired,
};

export default function ColumnSorting({name, field, sort, isSorted}) {

    const [sorting, setSorting] = useState(Trilean.NONE);

    useEffect(() => {
        if (sorting !== Trilean.NONE)
            sort(field, sorting)
    }, [sorting]);

    useEffect(() => {
        if (!isSorted)
            setSorting(Trilean.NONE)
    }, [isSorted]);

    const handleOnClick = () => {
        switch (sorting) {
            case Trilean.FALSE:
                setSorting(Trilean.TRUE);
                break;
            case Trilean.TRUE:
                setSorting(Trilean.FALSE);
                break;
            default:
                setSorting(Trilean.TRUE);
        }
    };

    return <th className={"column-sorting"} onClick={handleOnClick}>
        <div className="flex align-center">
            <span>{name}</span>
            <span className={"item push-right"}>
                {sorting === 1 && <Icon icon={IconNames.SORT_ASC} className={"text-primary"}/>}
                {sorting === -1 && <Icon icon={IconNames.SORT_DESC} className={"text-primary"}/>}
            </span>
        </div>
    </th>;
}