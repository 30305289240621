import React from "react";
import TopBar from "./TopBar";
import NavTabs from "./NavTabs";
import AccountList from "./account/AccountList";
import {useSelector} from "react-redux";

/**
 * Composant wrapper permettant d'afficher le tableau de bord de l'utilisateur.
 */

export default function Dashboard() {

    const account = useSelector(({accounts}) => accounts.current);

    return <>
        <TopBar/>
        <main id={"dashboard"}>
            {account ? <NavTabs/> : <AccountList/>}
        </main>
    </>;
}