import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {find} from "../../redux/actions/user.actions";
import Dashboard from "../Dashboard";
import Loader from "./Loader";

/**
 * Composant wrapper permettant d'afficher le tableau de bord de l'utilisateur.
 */

export default function PreLoader() {

    const dispatch = useDispatch();
    const username = useSelector(({auth}) => auth.username);
    const user = useSelector(({user}) => user);

    useEffect(() => dispatch(find(username)), [dispatch, username]);

    if (user.loading)
        return <Loader text={"Chargement du compte utilisateur ..."}/>;

    return <Dashboard/>;
}