import {FILE_ACTIONS} from "../actions/file.actions";
import {CONTACT_ACTIONS} from "../actions/contact.actions";

const initialState = {
    loading: false,
    file: undefined
};

export const fileUploader = (state = initialState, action) => {
    switch (action.type) {
        case FILE_ACTIONS.FILE_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CONTACT_ACTIONS.CLEAR_REQUEST:
        case FILE_ACTIONS.FILE_UPLOAD_FAILURE:
            return initialState;
        default:
            return state;
    }
};