import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

Pagination.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.oneOf([0, 1, 2]),
    onPageChanged: PropTypes.func.isRequired,
    rightIcon: PropTypes.element,
    leftIcon: PropTypes.element
};

Pagination.defaultProps = {
    pageLimit: 30,
    pageNeighbours: 0,
    rightIcon: <span aria-hidden="true">&raquo;</span>,
    leftIcon: <span aria-hidden="true">&laquo;</span>
};

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 * range(0, 10, 2) => [0, 2, 4, 6, 8, 10]
 * @param from
 * @param to
 * @param step
 * @returns {[]}
 */
const range = (from, to, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
        range.push(i);
        i += step;
    }
    return range;
};

/**1
 * @return {null}
 */
function Pagination({totalRecords, pageLimit, pageNeighbours, onPageChanged, leftIcon, rightIcon}) {

    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const gotoPage = page => setCurrentPage(Math.max(0, Math.min(page, totalPages)));

    useEffect(() => {
        gotoPage(1);
    }, []);

    useEffect(() => {
        onPageChanged({
            currentPage,
            totalPages,
            pageLimit,
            totalRecords
        });
    }, [currentPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(totalRecords / pageLimit));
    }, [pageLimit, totalRecords]);

    if (!totalRecords || totalPages === 1) return null;

    const handleClick = page => e => {
        e.preventDefault();
        gotoPage(page);
    };

    const handleMoveLeft = e => {
        e.preventDefault();
        gotoPage(currentPage - 1);
    };

    const handleMoveRight = e => {
        e.preventDefault();
        gotoPage(currentPage + 1);
    };

    const fetchPageNumbers = () => {
        /**
         * totalNumbers: the total page numbers to show on the control.
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls.
         */
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

            let pages = range(startPage, endPage);

            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }
                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }
                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            return [1, ...pages, totalPages];
        }
        return range(1, totalPages);
    };

    return <>
        <nav aria-label={"pagination"} className={"pagination"}>
            <ul className={"pagination"}>
                {
                    fetchPageNumbers().map((page, index) => {
                        if (page === LEFT_PAGE) return <a className="page-link" aria-label="Previous" onClick={handleMoveLeft} key={index}>
                            <li className="page-item">
                                {leftIcon}
                            </li>
                        </a>;

                        if (page === RIGHT_PAGE) return <a className="page-link" aria-label="Next" onClick={handleMoveRight} key={index} >
                            <li className="page-item">
                                {rightIcon}
                            </li>
                        </a>;

                        return <a className={`page-link ${currentPage === page ? " active" : ""}`} onClick={handleClick(page)} key={index}>
                            <li  className={"page-item"}>
                                {page}
                            </li>
                        </a>;
                    })
                }
            </ul>
        </nav>
    </>;
}

export default Pagination;