import React from "react";
import {useSelector} from "react-redux";
import {Alignment, Button, Icon, Menu, Navbar, Popover, Position} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useHistory} from "react-router-dom";

export default function TopBar() {

    const history = useHistory();
    const user = useSelector(({user}) => user.current);
    const extraToolbar = useSelector(({gui}) => gui.extraToolbar);

    const handleOnClick = () => history.push("/logout");

    return <Navbar className={"bp3-dark"}>
        <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading><Icon icon={IconNames.MOBILE_PHONE} iconSize={24}/></Navbar.Heading>
            <Navbar.Heading>SMS Sender</Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
            { extraToolbar }
            { extraToolbar && <Navbar.Divider />}
            <Popover position={Position.BOTTOM} content={<Menu>
                <Menu.Item icon={IconNames.LOG_OUT} onClick={handleOnClick} text="Se déconnecter"/>
            </Menu>}>
                <Button minimal icon="user" text={`${user.firstName} ${user.lastName}`}/>
            </Popover>
        </Navbar.Group>
    </Navbar>;
}