import React from "react";
import Login from "../components/Login";
import Logout from "../components/Logout";
import RequireAuth from "../components/RequireAuth";
import {Route, Switch} from "react-router-dom";
import PreLoader from "../../base/components/core/PreLoader";

export default function Router() {
    return <Switch>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/logout" component={Logout}/>
        <Route path="/" component={RequireAuth(PreLoader)}/>
    </Switch>;
}
