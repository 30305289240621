import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {logout} from '../redux/actions/auth.actions';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';

const Logout = ({history, logout}) => {

    useEffect(() => {
        // setTimeout(() => logout(history), 3000);
        logout(history);
    });

    return <div>Logout</div>;
};

const mapDispatchToProps = (dispatch) => bindActionCreators({logout}, dispatch);
export default withRouter(connect(null, mapDispatchToProps)(Logout));