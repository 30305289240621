import Axios from "axios";
import {APP} from "../../../config";
import {config} from "./base.service";

export const messageService = {
    create, paginate, submit
};

function create(message) {
    return Axios.post(`${APP.END_POINT}/message/save`, message, config());
}

function submit(message) {
    return Axios.post(`${APP.END_POINT}/message`, message, config());
}

function paginate({accountId, page, size}) {
    return Axios.get(`${APP.END_POINT}/message/${accountId}?page=${page}&size=${size}`, config());
}
