import React from 'react';
import {Button, Dialog, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {create} from "../../redux/actions/tag.actions";
import PropTypes from "prop-types";

TagCreateDialog.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default function TagCreateDialog({onClose}) {

    const {register, handleSubmit, errors, setValue} = useForm({mode: "onChange"});

    const {loading, isDialogOpened} = useSelector(({tags}) => tags);
    const {id} = useSelector(({accounts}) => accounts.current);

    const dispatch = useDispatch();

    const onSubmit = data => {
        data.ovhAccount = {id};
        dispatch(create(data));
    };

    const handleKeyBlur = ({target: {value}}) => {
        if (!value.startsWith("#")) value = "#" + value;
        setValue("label", value.toUpperCase());
    };

    return <Dialog isOpen={isDialogOpened} onClose={onClose}
                   title={"Ajouter un tag"} icon={IconNames.SMALL_PLUS}>
        <form onSubmit={handleSubmit(onSubmit)} className={"bp3-dialog-body"}>
            <FormGroup
                labelFor={"input-key"}
                labelInfo={"(requis)"}
                label={"Clé"}>
                <InputGroup
                    name={"label"}
                    inputRef={register({required: true, minLength: 3, maxLength: 20})}
                    type={"text"}
                    id={"input-key"}
                    placeholder={"Clé"}
                    onBlur={handleKeyBlur}
                    disabled={loading}
                    intent={errors.key && "danger"}/>
            </FormGroup>
            <FormGroup
                labelFor={"input-value"}
                label={"Valeur"}
                labelInfo={"(requis)"}>
                <InputGroup
                    name={"value"}
                    inputRef={register({required: true, minLength: 3, maxLength: 20})}
                    disabled={loading}
                    type={"text"}
                    id={"input-value"}
                    placeholder={"Valeur"}
                    intent={errors.value && "danger"}/>
            </FormGroup>
            <div className="bp3-dialog-footer-actions">
                <Button
                    type={"submit"}
                    intent={Intent.PRIMARY}
                    icon={IconNames.SMALL_PLUS}
                    text={"Ajouter"}
                    disabled={loading}/>
            </div>
        </form>
    </Dialog>;
}