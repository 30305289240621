import { AUTH_ACTIONS } from '../actions/auth.actions';
import jwt from 'jsonwebtoken';
import { HistoryUtil } from '@jetam/core';
import { APP } from '../../../config';

let initialState = checkSession();

export function auth(state = initialState, action) {
    switch (action.type) {
        case AUTH_ACTIONS.LOGIN_SUCCESS:
            return checkSession();
        case AUTH_ACTIONS.LOGIN_REQUEST:
            return { ...state, loading: true };
        case AUTH_ACTIONS.LOGIN_FAILURE:
            return {
                loading: false,
                loggingIn: false,
                message: action.error,
            };
        case AUTH_ACTIONS.LOGOUT_REQUEST:
        case AUTH_ACTIONS.LOGOUT_SUCCESS:
        case AUTH_ACTIONS.LOGOUT_FAILURE:
            return {
                loading: false,
                loggingIn: false,
            };
        default:
            return state;
    }
}

function checkSession() {
    if (localStorage.getItem(APP.STORAGE_USR) === null)
        return { loggingIn: false, loading: false };

    const user = JSON.parse(localStorage.getItem(APP.STORAGE_USR));
    const decodedToken = jwt.decode(user.token, { complete: true });

    if ((decodedToken.payload.exp - 3600) < Date.now() / 1000) HistoryUtil.push('/logout');
    return { loggingIn: true, loading: false, token: user.token, username: decodedToken.payload.sub };
}
