import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Card, HTMLTable, Icon, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useDispatch, useSelector} from "react-redux";
import {toggleCreateDialog} from "../../redux/actions/tag.actions";
import TagCreateDialog from "./TagCreateDialog";
import TagTableRow from "./TagTableRow";
import Loader from "../core/Loader";
import Pagination from "../core/Pagination";
import {Fade} from "react-reveal";

TagTable.propTypes = {};

const PAGE_LIMIT = 20;

export default function TagTable() {

    // region Reducers
    const {items, isDialogOpened, loading} = useSelector(({tags}) => tags);
    // endregion

    const [currentItems, setCurrentItems] = useState([]);
    const [dataPagination, setDataPagination] = useState(undefined);

    const dispatch = useDispatch();

    useEffect(() => {
        if (dataPagination)
            handlePageChanged(dataPagination);
    }, [items]);

    const handleCreateDialog = event => {
        event.preventDefault();
        dispatch(toggleCreateDialog());
    };

    // region Render
    const renderToolbar = () => <div className={"flex row-reverse"}>
        <ButtonGroup className={"row"}>
            <Button text={"Ajouter"} icon={IconNames.PLUS} onClick={handleCreateDialog} minimal/>
        </ButtonGroup>
    </div>;

    if (items.length === 0) return <NonIdealState
        className={"mt64"}
        icon={IconNames.NOTIFICATIONS}
        title={"Aucun élément trouvé !"}/>;

    const handlePageChanged = data => {
        const {currentPage, pageLimit} = data;
        const start = (currentPage - 1) * pageLimit;
        setCurrentItems(items.slice(start, start + pageLimit));
        setDataPagination({currentPage, pageLimit});
    };

    return <Fade big>
    <Card elevation={2}>
        {renderToolbar()}
        <TagCreateDialog isOpen={isDialogOpened} onClose={handleCreateDialog}/>
        <HTMLTable bordered interactive striped small id={"contact-tab"}>
            <thead>
            <tr>
                <th>Clé</th>
                <th>Valeur</th>
                <th>Date de création</th>
                <th>Créé par</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {currentItems.map(tag => <TagTableRow key={tag.id} tag={tag}/>)}
            </tbody>
        </HTMLTable>
        {loading && <Loader text={"Chargement ..."}/>}
        <Pagination totalRecords={items.length} onPageChanged={handlePageChanged} pageLimit={PAGE_LIMIT}
                    leftIcon={<Icon icon={IconNames.CHEVRON_LEFT}/>}
                    rightIcon={<Icon icon={IconNames.CHEVRON_RIGHT}/>}/>
    </Card>
    </Fade>;
    // endregion
}