import Axios from "axios";
import { APP } from "../../../config";

export const fileService = {
    upload,
};

const config = () => {
    const user = JSON.parse(localStorage.getItem(APP.STORAGE_USR));
    return {
        headers: {
            "Authorization": `Bearer ${user.token}`,
        },
    };
};

function upload(file, token, accountId) {
    const fd = new FormData();
    fd.append("file", file, file.name);
    fd.append("token", token);
    fd.append("accountId", accountId);
    return Axios.post(`${APP.END_POINT}/file`, fd, config());
}
