import { APP } from '../../../config';

export const config = () => {
    const user = JSON.parse(localStorage.getItem(APP.STORAGE_USR));
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`,
        },
    };
};