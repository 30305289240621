import moment from "moment";
import React from "react";
import {BrowserRouter} from "react-router-dom";
import "moment/locale/fr";
import "./scss/app.scss";
import {Provider} from "react-redux";
import store from "./redux/store";
import AuthRouter from "../auth/router/AuthRouter";
import AppToaster from "./components/AppToaster";

moment.locale("fr");

export default function App() {
    return <Provider store={store}>
        <BrowserRouter basename='/'>
            <AppToaster/>
            <AuthRouter/>
        </BrowserRouter>
    </Provider>;
}
