import React from "react";
import {Button, Icon, Intent, Navbar} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useDispatch} from "react-redux";
import {leave, select} from "../../redux/actions/account.actions";
import {changeExtraToolbar, cleanExtraToolbar} from "../../redux/actions/gui.actions";

export default function AccountListItem({account}) {

    const dispatch = useDispatch();

    const handleOnClick = () => {
        dispatch(select(account));
        dispatch(changeExtraToolbar(<>
            <span>{account.creditLeft.toLocaleString("fr-BE", { maximumFractionDigits: 2 })} {account.creditLeft > 0 ? "crédits" : "crédit"}</span>
            <Icon className={"title-icon mr16"} icon={IconNames.DOT} intent={account.enabled ? Intent.SUCCESS : Intent.DANGER}/>
            <Button icon={IconNames.CHANGES} text={account.label} minimal onClick={handleSwitchAccountBtn}/>
        </>));
    };

    const handleSwitchAccountBtn = () => {
        dispatch(leave());
        dispatch(cleanExtraToolbar());
    };

    return <div className={"account-list-item flex align-center"}>
        <div className={"item"}>
            <div className={"title flex align-center"}>
                <Icon className={"title-icon"} icon={IconNames.FULL_CIRCLE} iconSize={16}
                    intent={account.enabled ? Intent.SUCCESS : Intent.DANGER}/>
                <span className={"title-name"}>{account.label}</span>
            </div>
            <div className={"ovh"}>
                <span className={"ovh-icon"}>OVH</span>
                <span>{account.serviceName}</span>
            </div>
        </div>

        <div className={"item credit push-right"}>
            <div>{account.creditLeft.toLocaleString("fr-BE", { maximumFractionDigits: 2 })}</div>
            <div>crédits</div>
        </div>

        <div className="item">
            <Button icon={<Icon icon={IconNames.CHEVRON_RIGHT} iconSize={16}/>} minimal onClick={handleOnClick}/>
        </div>
    </div>;
}