import React, {useEffect, useState} from 'react';
import {InputGroup, Label} from "@blueprintjs/core";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {ObjectUtil} from "@jetam/core";
import {paginate} from "../../redux/actions/contact.actions";

SearchFilter.propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default function SearchFilter({fieldName, label}) {

    const {pagination} = useSelector(({contacts}) => contacts);
    const accountId = useSelector(({accounts}) => accounts.current.id);

    const [value, setValue] = useState("");
    const [isCleared, setIsCleared] = useState(true);

    const dispatch = useDispatch();

    const { search } = pagination;

    useEffect(() => {
        if (ObjectUtil.isEmpty(search)) {
            setIsCleared(true);
            setValue("");
        }  else if (!ObjectUtil.isEmpty(search[fieldName])) setValue(search[fieldName]);
    }, [pagination]);

    useEffect(() => {
        if(isCleared) setIsCleared(false); // Evite le double appel du dispatch lors du montage & lors du reset du champ
        let timeout = undefined;
        if(!isCleared) {
            timeout = setTimeout(() => {
                if (ObjectUtil.isEmpty(value)) delete search[fieldName];
                else search[fieldName] = value.toString();
                dispatch(paginate({accountId, pagination: {...pagination, search}}));
            }, 1000);
        }
        return () => timeout && clearTimeout(timeout);
    }, [value]);

    const handleChange = event => {
        event.preventDefault();
        const {target: {value}} = event;
        setValue(value.trim());
    };

    return <div className="flex filter-wrapper column">
        <Label htmlFor={`input-${fieldName}`}>Colonne {label}</Label>
        <InputGroup placeholder={`Rechercher un ${label}`}
                    id={`input-${fieldName}`}
                    name={fieldName}
                    value={value}
                    onChange={handleChange}
        />
    </div>;
}
