import {USER_ACTIONS} from '../actions/user.actions';
import {ACCOUNT_ACTIONS} from "../actions/account.actions";

const initialState = {
    loading: true,
    current: undefined,
};

export const user = (state = initialState, action) => {
    switch (action.type) {
        // region user
        case USER_ACTIONS.FIND_REQUEST:
            return initialState;
        case USER_ACTIONS.FIND_SUCCESS:
            return {
                ...state,
                current: action.payload,
                loading: false
            };
        case USER_ACTIONS.FIND_FAILURE:
            return {
                ...state,
                loading: false
            };
        // endregion
        default:
            return state;
    }
};