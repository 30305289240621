import Axios from "axios";
import { APP } from "../../../config";
import { config } from "./base.service";

export const logService = {
    findAll, find, paginate
};

function findAll(accountId) {
    return Axios.get(`${APP.END_POINT}/log/account/${accountId}`, config());
}

function paginate(accountId, page, size) {
    return Axios.get(`${APP.END_POINT}/log/account/${accountId}?page=${page}&size=${size}`, config());
}

function find(logId) {
    return Axios.get(`${APP.END_POINT}/log/${logId}`, config());
}
