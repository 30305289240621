import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    FormGroup,
    InputGroup,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Popover,
    PopoverPosition,
    Tag
} from "@blueprintjs/core";
import {useDispatch, useSelector} from "react-redux";
import {Fade} from "react-reveal";
import {IconNames} from "@blueprintjs/icons";
import {create, submit} from "../../redux/actions/composer.actions";
import Loader from "../core/Loader";

const SMS_PRICE = 0.06;
const MIN_LENGTH_TEXTAREA = 10;
const MIN_LENGTH_TITLE = 3;

export default function Composer() {

    const textAreaRef = useRef(undefined);
    const dispatch = useDispatch();

    // region State
    const [lengthMsg, setLengthMsg] = useState(0);
    const [smsCount, setSmsCount] = useState(0);
    const [smsTotalPrice, setSmsTotalPrice] = useState(0);

    const [cursorPosition, setCursorPosition] = useState(0);
    const [nextCursorPosition, setNextCursorPosition] = useState(0);
    const [disabledButton, setDisabledButton] = useState(true);

    const [popOverOpened, setPopOverOpened] = useState(false);
    const [textareaValue, setTextareaValue] = useState("");
    const [titleValue, setTitleValue] = useState("");
    // endregion

    // region Selectors
    const contacts = useSelector(({contacts}) => contacts.items);
    const recipientsCount = useSelector(({contacts}) => contacts.recipientsCount);
    const {current: currentMessage, loading} = useSelector(({composer}) => composer);
    const tags = useSelector(({tags}) => tags.items);
    const accountId = useSelector(({accounts}) => accounts.current.id);
    // endregion

    // region Effects
    useEffect(() => {
        if (currentMessage) {
            setTitleValue(currentMessage.title);
            setTextareaValue(currentMessage.content);
            // calculateSmsCount();
            // calculateTotalPrice();
        } else {
            setTitleValue("");
            setTextareaValue("");
        }
    }, [currentMessage]);


    /**
     * Lorsque la valeur du textarea ou du tittre change, on vérifie si les boutons peuvent être activiés.
     */
    useEffect(() => setDisabledButton(titleValue.length < MIN_LENGTH_TITLE || textareaValue.length < MIN_LENGTH_TEXTAREA), [textareaValue, titleValue]);

    useEffect(() => calculateTotalPrice(), [smsCount, recipientsCount]);

    useEffect(() => undefined, [textareaValue, recipientsCount]);

    useEffect(() => {
        textAreaRef.current.focus();
        textAreaRef.current.setSelectionRange(nextCursorPosition, nextCursorPosition);
    }, [nextCursorPosition]);

    // endregion

    const calculateTotalPrice = () => setSmsTotalPrice(smsCount * SMS_PRICE * recipientsCount);

    // const calculateSmsCount = () => {
    //
    //     // setSmsCount(Math.ceil(length / SMS_MAX_CHAR));
    // };

    const handleTextareaChange = ({target: {value}}) => {
        setTextareaValue(value);
        setLengthMsg(value.length);
        if (value.length === 0) reset();
    };

    const reset = () => {
        setSmsCount(0);
        setSmsTotalPrice(0);
    };

    const handleTextareaKeyPress = (event) => {
        const {key} = event;
        setCursorPosition(event.target.selectionStart);
        setPopOverOpened(key === "#");
    };

    const addContentInTextarea = value => {
        let textBeforeCursorPosition = textAreaRef.current.value.substring(0, cursorPosition + 1);
        let textAfterCursorPosition = textAreaRef.current.value.substring(cursorPosition + 1, textAreaRef.current.length);
        setTextareaValue(textBeforeCursorPosition + value + textAfterCursorPosition);
        setPopOverOpened(false);
        setNextCursorPosition(cursorPosition + value.length + 1);
    };

    const handleTitleChange = ({target: {value}}) => setTitleValue(value);

    const handleSaveClick = () => dispatch(create({
        "title": titleValue,
        "content": textareaValue,
        "ovhAccount": {
            "id": accountId
        }
    }));

    const handleSubmit = () => dispatch(submit({
        "title": titleValue,
        "content": textareaValue,
        "ovhAccount": {
            "id": accountId
        },
        "contacts": contacts.filter(c => c.selected).map(c => ({
            id: c.id
        }))
    }));

    if (loading) return <Loader text={"Envoi en cours ..."}/>;

    const renderToolbar = () => <div className={"flex row-reverse"}>
        <Button text={"Sauvegarder"} onClick={handleSaveClick} icon={IconNames.FLOPPY_DISK} disabled={disabledButton} minimal/>
    </div>;

    return <Fade big>
        <div id={"composer"}>
            <Card elevation={2}>
                {renderToolbar()}
                <FormGroup
                    labelFor={"input-title"}
                    labelInfo={"(requis)"}
                    label={"Titre"}>
                    <InputGroup
                        name={"username"}
                        onChange={handleTitleChange}
                        value={titleValue}
                        type={"text"}
                        id={"input-title"}
                        placeholder={"max. 35 caractères"}
                        large
                    />
                </FormGroup>

                <FormGroup
                    labelFor={"input-message"}
                    labelInfo={"(requis)"}
                    label={"Message"}>
                    <Popover isOpen={popOverOpened}
                             fill
                             position={PopoverPosition.RIGHT}
                             content={<Menu>
                                 <MenuDivider title="Tags"/>
                                 {tags.map(tag => <MenuItem key={tag.id} text={<><Tag>{tag.label}</Tag> {tag.value}</>}
                                                            onClick={() => addContentInTextarea(tag.label.substr(1))}/>)}
                             </Menu>}>
                    <textarea
                        ref={textAreaRef}
                        name={"message"}
                        value={textareaValue}
                        id={"input-message"}
                        placeholder={"Votre message"}
                        className={"bp3-input bp3-fill bp3-large"}
                        onChange={handleTextareaChange}
                        onKeyPress={handleTextareaKeyPress}
                    />
                    </Popover>
                </FormGroup>

                <div className="flex">
                    <Card className={"item f50 flex column align-center"}>
                        <div className={"item"}>Contacts</div>
                        <div className={"item"}>{recipientsCount}</div>
                    </Card>

                    <Card className={"item f50 flex column align-center"}>
                        <div className={"item"}>SMS envoyés</div>
                        <div className={"item"}>{lengthMsg}</div>
                    </Card>
                </div>

                <div className="flex">
                    <Card className={"item f50 flex column align-center"}>
                        <div className={"item"}>Coût SMS</div>
                        <div className={"item"}>{SMS_PRICE.toLocaleString("fr-BE", {
                            style: "currency",
                            currency: "EUR",
                            maximumFractionDigits: 3
                        })}</div>
                    </Card>

                    <Card className={"item f50 flex column align-center"}>
                        <div className={"item"}>Coût total</div>
                        <div className={"item"}>{smsTotalPrice.toLocaleString("fr-BE", {
                            style: "currency",
                            currency: "EUR",
                            maximumFractionDigits: 2
                        })}</div>
                    </Card>
                </div>

                <div className="flex">
                    <div className={"item f50"}/>
                    <Button className={"item f50"} text={"Envoyer"} rightIcon={IconNames.ARROW_RIGHT}
                            intent={disabledButton ? Intent.NONE : Intent.SUCCESS}
                            onClick={handleSubmit}
                            disabled={disabledButton}/>
                </div>
            </Card>
        </div>
    </Fade>;
}
