import {contactService} from "../services/contact.service";
import {addToast} from "./toaster.actions";

export const CONTACT_ACTIONS = {
    TOGGLE_CONTACT: "CONTACT_TOGGLE_SINGLE",
    TOGGLE_ALL_CONTACTS: "CONTACT_TOGGLE_ALL",

    CLEAR_REQUEST: "CONTACT_CLEAR_ALL_REQUEST",

    FIND_ALL_REQUEST: "CONTACT_FIND_ALL_REQUEST",
    FIND_ALL_FAILURE: "CONTACT_FIND_ALL_FAILURE",
    FIND_ALL_SUCCESS: "CONTACT_FIND_ALL_SUCCESS",

    FIND_FILTERS_REQUEST: "CONTACT_FIND_FILTERS_REQUEST",
    FIND_FILTERS_FAILURE: "CONTACT_FIND_FILTERS_FAILURE",
    FIND_FILTERS_SUCCESS: "CONTACT_FIND_FILTERS_SUCCESS",

    SORT_REQUEST: "CONTACT_SORT_ALL_REQUEST",
    SORT_FAILURE: "CONTACT_SORT_ALL_FAILURE",
    SORT_SUCCESS: "CONTACT_SORT_ALL_SUCCESS",

    FILTER_FIELD_REQUEST: "CONTACT_FILTER_FIELD_REQUEST",
    SEARCH_FIELD_REQUEST: "CONTACT_SEARCH_FIELD_REQUEST",

    OPEN_PHONE_DIALOG_REQUEST: "CONTACT_OPEN_PHONE_DIALOG_REQUEST",
    CLOSE_PHONE_DIALOG_REQUEST: "CONTACT_CLOSE_PHONE_DIALOG_REQUEST",

    UPDATE_REQUEST: "CONTACT_UPDATE_REQUEST",
    UPDATE_FAILURE: "CONTACT_UPDATE_FAILURE",
    UPDATE_SUCCESS: "CONTACT_UPDATE_SUCCESS",

    COUNT_REQUEST: "CONTACT_COUNT_REQUEST",
    COUNT_FAILURE: "CONTACT_COUNT_FAILURE",
    COUNT_SUCCESS: "CONTACT_COUNT_SUCCESS",
};

export const clear = () => dispatch => dispatch({type: CONTACT_ACTIONS.CLEAR_REQUEST});

export const toggle = contact => dispatch => dispatch({type: CONTACT_ACTIONS.TOGGLE_CONTACT, payload: contact});

export const toggleAll = () => dispatch => dispatch({type: CONTACT_ACTIONS.TOGGLE_ALL_CONTACTS});

export const closeEditPhoneDialog = () => dispatch => dispatch({type: CONTACT_ACTIONS.CLOSE_PHONE_DIALOG_REQUEST})

export const openEditPhoneDialog = (contact, submitAction) => dispatch => dispatch({
    type: CONTACT_ACTIONS.OPEN_PHONE_DIALOG_REQUEST,
    payload: {contact, submitAction}
})

export const update = contact => dispatch => {
    dispatch({type: CONTACT_ACTIONS.UPDATE_REQUEST});
    contactService.update(contact)
        .then(res => {
            dispatch({type: CONTACT_ACTIONS.UPDATE_SUCCESS, payload: res.data})
            dispatch({type: CONTACT_ACTIONS.CLOSE_PHONE_DIALOG_REQUEST})
            dispatch(addToast({
                icon: "tick",
                intent: "success",
                message: "Contact modifié avec succès !"
            }));
        })
        .catch(err => {
            dispatch({type: CONTACT_ACTIONS.UPDATE_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};

export const paginate = pageable => dispatch => {
    dispatch({type: CONTACT_ACTIONS.FIND_ALL_REQUEST});
    contactService.paginate(pageable)
        .then(res => {
            dispatch({type: CONTACT_ACTIONS.FILTER_FIELD_REQUEST, payload: pageable.pagination.filters});
            dispatch({type: CONTACT_ACTIONS.SEARCH_FIELD_REQUEST, payload: pageable.pagination.search});
            dispatch({type: CONTACT_ACTIONS.FIND_ALL_SUCCESS, payload: res.data});
        })
        .catch(err => {
            dispatch({type: CONTACT_ACTIONS.FIND_ALL_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};

export const count = accountId => dispatch => {
    dispatch({type: CONTACT_ACTIONS.FIND_ALL_REQUEST});
    contactService.count(accountId)
        .then(res => dispatch({type: CONTACT_ACTIONS.COUNT_SUCCESS, payload: res.data}))
        .catch(err => {
            dispatch({type: CONTACT_ACTIONS.COUNT_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};

export const findFilters = accountId => dispatch => {
    dispatch({type: CONTACT_ACTIONS.FIND_FILTERS_REQUEST});
    contactService.findFilters(accountId)
        .then(res => dispatch({type: CONTACT_ACTIONS.FIND_FILTERS_SUCCESS, payload: res.data}))
        .catch(err => {
            dispatch({type: CONTACT_ACTIONS.FIND_FILTERS_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
}

    