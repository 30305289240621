import {addToast} from "./toaster.actions";
import {messageService} from "../services/message.service";
import {toggleComposerTab} from "./gui.actions";

export const MESSAGE_ACTIONS = {
    SUBMIT_REQUEST: "MESSAGE_SUBMIT_REQUEST",
    SUBMIT_SUCCESS: "MESSAGE_SUBMIT_SUCCESS",
    SUBMIT_FAILURE: "MESSAGE_SUBMIT_FAILURE",

    CREATE_REQUEST: "MESSAGE_CREATE_REQUEST",
    CREATE_SUCCESS: "MESSAGE_CREATE_SUCCESS",
    CREATE_FAILURE: "MESSAGE_CREATE_FAILURE",

    FIND_ALL_FAILURE: "MESSAGE_FIND_ALL_FAILURE",
    FIND_ALL_SUCCESS: "MESSAGE_FIND_ALL_SUCCESS",
    FIND_ALL_REQUEST: "MESSAGE_FIND_ALL_REQUEST",

    SELECT_FAILURE: "MESSAGE_SELECT_FAILURE",
    SELECT_SUCCESS: "MESSAGE_SELECT_SUCCESS",
    SELECT_REQUEST: "MESSAGE_SELECT_REQUEST",

    CHANGE_REQUEST: "MESSAGE_SELECT_REQUEST",
    CHANGE_SUCCESS: "MESSAGE_SELECT_SUCCESS",
    CHANGE_FAILURE: "MESSAGE_SELECT_FAILURE",
};

export const submit = message => dispatch => {
    dispatch({type: MESSAGE_ACTIONS.SUBMIT_REQUEST});
    messageService.submit(message)
        .then(res => {
            dispatch({type: MESSAGE_ACTIONS.SUBMIT_SUCCESS, payload: res.data});
            dispatch(addToast({
                icon: "tick",
                intent: "success",
                message: "Message envoyé avec succès !"
            }));
        })
        .catch(err => {
            dispatch({type: MESSAGE_ACTIONS.SUBMIT_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};

export const create = message => dispatch => {
    dispatch({type: MESSAGE_ACTIONS.CREATE_REQUEST});
    messageService.create(message)
        .then(res => {
            dispatch({type: MESSAGE_ACTIONS.CREATE_SUCCESS, payload: res.data});
            dispatch(addToast({
                icon: "tick",
                intent: "success",
                message: "Message enregistré avec succès!"
            }));
        })
        .catch(err => {
            dispatch({type: MESSAGE_ACTIONS.CREATE_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};

export const paginate = pageable => dispatch => {
    dispatch({type: MESSAGE_ACTIONS.FIND_ALL_REQUEST});
    messageService.paginate(pageable)
        .then(res => dispatch({type: MESSAGE_ACTIONS.FIND_ALL_SUCCESS, payload: res.data}))
        .catch(err => {
            dispatch({type: MESSAGE_ACTIONS.FIND_ALL_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};

export const select = message => dispatch => {
    dispatch({type: MESSAGE_ACTIONS.SELECT_SUCCESS, payload: message});
    dispatch(toggleComposerTab());
    dispatch(addToast({
        icon: "tick",
        intent: "success",
        message: "Message chargé avec succès !"
    }));
};

export const change = message => dispatch => dispatch({type: MESSAGE_ACTIONS.CHANGE_SUCCESS, payload: message});