import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card} from "@blueprintjs/core";
import {back} from "../../redux/actions/log.actions";
import {useDispatch} from "react-redux";
import {IconNames} from "@blueprintjs/icons";
import {Fade} from "react-reveal";

LogDetails.propTypes = {
    log: PropTypes.object.isRequired
};

export default function LogDetails({log: current}) {

    const dispatch = useDispatch();

    const handleReturnClick = event => {
        event.preventDefault();
        dispatch(back());
    };

    return <Fade big>
        <Card elevation={2}>
            <div id={"single-log"}>
                <div className={"flex row-reverse"}>
                    <Button text={"Retour"} onClick={handleReturnClick} icon={IconNames.CHEVRON_LEFT} minimal/>
                </div>
                <h1>{current.log.filename}</h1>
                <div className={"content"}>
                    <code dangerouslySetInnerHTML={{__html: current.content}}/>
                </div>
            </div>
        </Card>
    </Fade>;
}