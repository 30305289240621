import {addToast} from "./toaster.actions";
import {userService} from "../services/user.service";

export const USER_ACTIONS = {
    FIND_REQUEST: "USER_FIND_REQUEST",
    FIND_SUCCESS: "USER_FIND_SUCCESS",
    FIND_FAILURE: "USER_FIND_FAILURE",
};


export const find = username => dispatch => {
    dispatch({type: USER_ACTIONS.FIND_REQUEST});
    userService.find(username)
        .then(res => dispatch({type: USER_ACTIONS.FIND_SUCCESS, payload: res.data}))
        .catch(err => {
            dispatch({type: USER_ACTIONS.FIND_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data
            }));
        });
};