import React from 'react';
import * as PropTypes from 'prop-types';

const BlankLink = ({ href, label, title, ...other }) =>
    <a role="link" target="_blank" href={href} title={title} rel="noopener noreferrer" {...other}>{label}</a>;

BlankLink.propTypes = {
    href: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]).isRequired,
    title: PropTypes.string,
};

export default BlankLink;
