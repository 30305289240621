import React, {useEffect, useState} from "react";
import ContactTable from "./contact/ContactTable";
import Composer from "./composer/Composer";
import {Fade} from "react-reveal";
import {Tab, Tabs} from "@blueprintjs/core";
import {useDispatch, useSelector} from "react-redux";
import ContactUploader from "./contact/ContactUploader";
import TemplateTable from "./template/TemplateTable";
import {toggleComposerTab, toggleFilterContacts} from "../redux/actions/gui.actions";
import TagTable from "./tag/TagTable";
import ContactFilterDrawer from "./contact/ContactFilterDrawer";
import LogTable from "./log/LogTable";
import {count} from "../redux/actions/contact.actions";
import {findAll} from "../redux/actions/tag.actions";

export default function NavTabs() {

    // region State
    const [tabId, setTabId] = useState("tcomp");
    // endregion

    // region Selectors
    const {totalItems} = useSelector(({contacts}) => contacts);
    const accountId = useSelector(({accounts}) => accounts.current.id);
    const {isComposerTabEnabled, isFilterOpened} = useSelector(({gui}) => gui);
    // endregion

    const dispatch = useDispatch();

    // region Effects
    useEffect(() => {
        if (isComposerTabEnabled)
            setTabId("tcomp");
    }, [isComposerTabEnabled]);

    useEffect(() => {
        dispatch(count(accountId))
        dispatch(findAll(accountId))
    }, [accountId]);
    // endregion

    // region Events
    const handleTabChange = tabId => {
        if (isComposerTabEnabled && tabId !== "tcomp ") dispatch(toggleComposerTab());
        setTabId(tabId);
    };

    const handleFilterClick = () => dispatch(toggleFilterContacts());
    // endregion

    // region Render
    if (totalItems <= 0) return <ContactUploader/>;

    return <Fade big>
        <ContactFilterDrawer isOpen={isFilterOpened} onClose={handleFilterClick}/>
        <div className={"flex wrap"} id={"composer-wrapper"}>
            <Tabs renderActiveTabPanelOnly large
                  onChange={handleTabChange} selectedTabId={tabId} >
                <Tab id={"tcomp"} title={"Composer"} panel={<Composer/>}/>
                <Tabs.Expander/>
                <Tab id={"tcont"} title={"Contacts"} panel={<ContactTable id={"contact-tab"}/>}/>
                <Tab id={"ttag"} title={"Tags"} panel={<TagTable/>}/>
                <Tab id={"tmsg"} title={"Templates"} panel={<TemplateTable/>}/>
                <Tab id={"tlog"} title={"Logs"} panel={<LogTable/>}/>
            </Tabs>
        </div>
    </Fade>;
    // endregion
}