import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Divider, FormGroup, Icon, InputGroup, Intent} from "@blueprintjs/core";
import {useForm} from "react-hook-form";
import {login as submitLogin} from "../redux/actions/auth.actions";
import {useHistory} from "react-router-dom";
import {IconNames} from "@blueprintjs/icons";
import Loader from "../../base/components/core/Loader";
import {Fade} from "react-reveal";

export default function Login() {

    const {register, handleSubmit, errors} = useForm({mode: "onChange"});
    const loading = useSelector(({auth}) => auth.loading);
    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = data => dispatch(submitLogin(data, history));

    const renderForm = () => <form onSubmit={handleSubmit(onSubmit)} className={"login-form"}>
        <FormGroup
            labelFor={"input-username"}
            labelInfo={"(requis)"}
            label={"Nom d'utilisateur"}>
            <InputGroup
                name={"username"}
                leftIcon={<Icon icon={IconNames.PERSON} intent={errors.username && "danger"}/>}
                inputRef={register({required: true})}
                type={"text"}
                id={"input-username"}
                placeholder={"Nom d'utilisateur"}
                disabled={loading}
                large
                intent={errors.username && "danger"}/>
        </FormGroup>
        <FormGroup
            labelFor={"input-password"}
            label={"Mot de passe"}
            labelInfo={"(requis)"}>
            <InputGroup
                name={"password"}
                leftIcon={<Icon icon={IconNames.LOCK} intent={errors.username && "danger"}/>}
                inputRef={register({required: true})}
                disabled={loading}
                type={"password"}
                id={"input-password"}
                placeholder={"Mot de passe"}
                large
                intent={errors.username && "danger"}/>
        </FormGroup>
        <Button
            className={"btn-login-form"}
            type={"submit"}
            intent={Intent.PRIMARY}
            fill
            large
            icon={IconNames.LOG_IN}
            text={"Se connecter"}
            disabled={loading}/>

        {loading && <Loader text={"Connexion au serveur d'authentification ..."} className={"mt16"}/>}
    </form>;

    return <main className={"login"}>
        <Fade big>
            <div className={"login_wrapper"}>
                <div className={"login_card"}>
                    <h1>SMS Sender</h1>
                    {/*<h4>&copy; jetam & cls</h4>*/}
                    <Divider/>
                    {renderForm()}
                </div>
            </div>
        </Fade>
    </main>;
};