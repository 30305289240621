import {TAG_ACTIONS} from "../actions/tag.actions";

const initialState = {
    loading: false,
    items: [],
    isDialogOpened: false
};

export const tags = (state = initialState, action) => {
    switch (action.type) {
        case TAG_ACTIONS.REMOVE_REQUEST:
        case TAG_ACTIONS.CREATE_REQUEST:
        case TAG_ACTIONS.FIND_ALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case TAG_ACTIONS.REMOVE_FAILURE:
        case TAG_ACTIONS.CREATE_FAILURE:
        case TAG_ACTIONS.FIND_ALL_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case TAG_ACTIONS.FIND_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload
            };
        case TAG_ACTIONS.CREATE_SUCCESS: {
            const items = [...state.items, action.payload];
            items.sort((a, b) => {
                if (a.label > b.label) return 1;
                else if (a.label < b.label) return -1;
                return 0;
            });
            return {
                ...state,
                loading: false,
                items
            };
        }
        case TAG_ACTIONS.REMOVE_SUCCESS: {
            let items = state.items.slice();
            const index = items.findIndex(tag => tag.label === action.payload
                .label);
            items.splice(index, 1);
            return {
                ...state,
                loading: false,
                items
            };
        }
        case TAG_ACTIONS.TOGGLE_CREATE_DIALOG:
            return {
                ...state,
                isDialogOpened: !state.isDialogOpened
            };
        default:
            return state;
    }
};