export const TOASTER_ACTIONS = {
    ADD_TOAST: 'ADD_TOAST',
    CLEAR_TOAST: 'CLEAR_TOAST'

};

// toast = { action, iconName, intent, message, onDismiss, timeout }
export const addToast= toast => dispatch =>
    dispatch({
        type: TOASTER_ACTIONS.ADD_TOAST, payload: toast
    });

export const clearToasts = () => ({ type: TOASTER_ACTIONS.CLEAR_TOAST });

