import React from "react";
import {Alignment, Button, ButtonGroup, Callout, Card, Checkbox, HTMLTable, Icon, Intent} from "@blueprintjs/core";
import {useDispatch, useSelector} from "react-redux";
import ContactTableRow from "./ContactTableRow";
import {clear, closeEditPhoneDialog, paginate, toggleAll} from "../../redux/actions/contact.actions";
import {IconNames} from "@blueprintjs/icons";
import {toggleFilterContacts} from "../../redux/actions/gui.actions";
import Pagination from "../core/Pagination";
import ColumnSorting from "../core/ColumnSorting";
import Loader from "../core/Loader";
import {Fade} from "react-reveal";
import EditPhoneNumberDialog from "./EditPhoneNumberDialog";

export default function ContactTable() {

    // region Reducers
    const {loading, pagination, items, totalItems, updatedAt, checkbox, isPhoneDialogOpened} = useSelector(({contacts}) => contacts);
    const accountId = useSelector(({accounts}) => accounts.current.id);
    // endregion

    // Update lorsque la liste des contacts est mise à jour notamment dans le cadre d'un filtrage des champs.
    // useEffect(() => setCurrentContacts(contacts.filtered.slice(offset, offset + PAGE_LIMIT)), [contacts]);

    const dispatch = useDispatch();

    // region Events
    const handleCheckbox = () => dispatch(toggleAll());

    const handleImportClick = () => dispatch(clear());

    const handleFilterClick = () => dispatch(toggleFilterContacts());

    const handlePageChanged = data => {
        const {currentPage, pageLimit} = data;
        dispatch(paginate({
            accountId,
            pagination: {
                ...pagination,
                page: currentPage,
                pageLimit,
            }
        }))
    };

    const handleSort = (field, direction) => {
        dispatch(paginate({
                accountId,
                pagination: {
                    ...pagination,
                    sort: {
                        field,
                        direction: direction === -1 ? "desc" : "asc"
                    }
                }
            })
        );
    };

    const handleClose = event => {
        event.preventDefault();
        dispatch(closeEditPhoneDialog());
    }
    //  endregion

    // region Render
    const renderToolbar = () => <div className={"flex row-reverse"}>
        <ButtonGroup className={"row"} minimal>
            <Button text={"Importer"} onClick={handleImportClick} icon={IconNames.IMPORT}/>
            <Button text={"Filtrer"} onClick={handleFilterClick} icon={IconNames.FILTER} intent={Intent.PRIMARY}/>
        </ButtonGroup>
    </div>;

    const { sort, pageLimit } = pagination;

    return <Fade big>
        <Card elevation={2}>
            <Callout icon={IconNames.INFO_SIGN} intent={Intent.PRIMARY}>Dernière importation
                : <strong>{updatedAt}</strong></Callout>
            {renderToolbar()}
            {isPhoneDialogOpened && <EditPhoneNumberDialog isOpen={isPhoneDialogOpened} onClose={handleClose}/>}
            <HTMLTable bordered interactive striped small id={"contact-tab"}>
                <thead>
                <tr>
                    <th className={"checkbox-cell"}>
                        <Checkbox checked={checkbox === 1}
                                  indeterminate={checkbox === 0}
                                  itent={Intent.PRIMARY}
                                  onChange={handleCheckbox} alignIndicator={Alignment.CENTER}/>
                    </th>
                    <ColumnSorting name={"Téléphone"} field={"phoneNumber"} sort={handleSort}
                                   isSorted={sort && sort.field === "phoneNumber"}/>
                    <ColumnSorting name={"Nom"} field={"lastName"} sort={handleSort}
                                   isSorted={sort && sort.field === "lastName"}/>
                    <ColumnSorting name={"Prénom"} field={"firstName"} sort={handleSort}
                                   isSorted={sort && sort.field === "firstName"}/>
                    <ColumnSorting name={"Société"} field={"society"} sort={handleSort}
                                   isSorted={sort && sort.field === "society"}/>
                    <ColumnSorting name={"C1"} field={"field1"} sort={handleSort}
                                   isSorted={sort && sort.field === "field1"}/>
                    <ColumnSorting name={"C2"} field={"field2"} sort={handleSort}
                                   isSorted={sort && sort.field === "field2"}/>
                    <ColumnSorting name={"C3"} field={"field3"} sort={handleSort}
                                   isSorted={sort && sort.field === "field3"}/>
                    <ColumnSorting name={"C4"} field={"field4"} sort={handleSort}
                                   isSorted={sort && sort.field === "field4"}/>
                    <ColumnSorting name={"C5"} field={"field5"} sort={handleSort}
                                   isSorted={sort && sort.field === "field5"}/>
                    <th className={"checkbox-cell"}>Message personnalisé</th>
                </tr>
                </thead>
                <tbody>
                {items.map(item => <ContactTableRow key={item.id} contact={item}/>)}
                </tbody>
            </HTMLTable>
            {loading && <Loader text={"Chargement des contacts ..."}/>}
            <Pagination totalRecords={totalItems} onPageChanged={handlePageChanged} pageLimit={pageLimit}
                        leftIcon={<Icon icon={IconNames.CHEVRON_LEFT}/>}
                        rightIcon={<Icon icon={IconNames.CHEVRON_RIGHT}/>}
            />
        </Card>
    </Fade>;
    // endregion
}
