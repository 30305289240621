import React, {useEffect} from 'react';
import {Button, ButtonGroup, Divider, Drawer, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useDispatch, useSelector} from "react-redux";
import SelectFilter from "../core/SelectFilter";
import PropTypes from "prop-types";
import {findFilters, paginate} from "../../redux/actions/contact.actions";
import SearchFilter from "../core/SearchFilter";
import {ObjectUtil} from "@jetam/core";

ContactFilterDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default function ContactFilterDrawer({isOpen, onClose}) {

    const filter = useSelector(({contactFilter}) => contactFilter);
    const {pagination} = useSelector(({contacts}) => contacts);
    const accountId = useSelector(({accounts}) => accounts.current.id);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpen) dispatch(findFilters(accountId));
    }, [isOpen]);

    const handleClearButton = event => {
        event.preventDefault();
        dispatch(paginate({
            accountId,
            pagination: {
                ...pagination,
                filters: {},
                search: {}
            }
        }));
    }

    return <Drawer isOpen={isOpen} onClose={onClose}
                   className={"drawer-filter"}
                   hasBackdrop={false}
                   title={"Filtres"}
                   icon={IconNames.FILTER}
                   size={"30%"}>

        <div className="drawer-wrapper">
            <div className={"flex row-reverse"}>
                <ButtonGroup className={"row"} minimal>
                    <Button text={"Clear All"} onClick={handleClearButton} icon={IconNames.FILTER_REMOVE}
                            disabled={ObjectUtil.isEmpty(pagination.filters) && ObjectUtil.isEmpty(pagination.search)}
                            intent={Intent.PRIMARY}/>
                </ButtonGroup>
            </div>

            <SelectFilter items={filter.field1} fieldName={"field1"} label={"C1"}/>
            <SelectFilter items={filter.field2} fieldName={"field2"} label={"C2"}/>
            <SelectFilter items={filter.field3} fieldName={"field3"} label={"C3"}/>
            <SelectFilter items={filter.field4} fieldName={"field4"} label={"C4"}/>
            <SelectFilter items={filter.field5} fieldName={"field5"} label={"C5"}/>
            <Divider/>
            <SearchFilter fieldName={"phoneNumber"} label={"Téléphone"}/>
            <SearchFilter fieldName={"lastName"} label={"Nom"}/>
            <SearchFilter fieldName={"firstName"} label={"Prénom"}/>
            <SearchFilter fieldName={"society"} label={"Société"}/>
        </div>
    </Drawer>;
}
