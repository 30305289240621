import {IconNames} from "@blueprintjs/icons";
import {Button, Dialog, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";

import {parsePhoneNumberFromString} from 'libphonenumber-js'

export default function EditPhoneNumberDialog({isOpen, onClose}) {

    const {contactSelected: contact, updateAction} = useSelector(({contacts}) => contacts);
    const accountId = useSelector(({accounts}) => accounts.current.id);

    // form
    const {register, handleSubmit, errors, setValue} = useForm({mode: "onChange"});

    useEffect(() => {
        if (contact) setTimeout(() => setValue("phoneNumber", contact.phoneNumber, true));
    }, [setValue, contact]);

    const onSubmit = data => {
        const update = Object.assign({}, contact);
        update.phoneNumber = data.phoneNumber;
        update.ovhAccount = {id: accountId};
        updateAction(update);
    };

    const handleChange = event => {
        event.preventDefault();
        const {value} = event.target;
        setValue("phoneNumber", value.replace(/ /g, ""));
    }

    function validatePhoneNumber(value) {
        if (value === undefined)
            return false;
        const phoneNumber = parsePhoneNumberFromString(value);
        if (!phoneNumber)
            return false;
        return phoneNumber.isValid();
    }

    return <Dialog isOpen={isOpen} onClose={onClose}
                   title={"Modifier le numéro de téléphone"} icon={IconNames.EDIT}>
        <form onSubmit={handleSubmit(onSubmit)} className={"bp3-dialog-body"}>
            <FormGroup
                labelFor={"input-phone"}
                labelInfo={"(requis)"}
                helperText={errors.phoneNumber &&
                <code>Le numéro de téléphone doit respecter la norme E164 en commençant par l'indicatif du pays (+32
                    pour la Belgique) !</code>}
                label={"Numéro de téléphone"}>
                <InputGroup
                    name={"phoneNumber"}
                    inputRef={register({required: true, validate: value => validatePhoneNumber(value)})}
                    type={"text"}
                    id={"input-phone"}
                    placeholder={"Numéro de téléphone"}
                    onChange={handleChange}
                    // disabled={loading}
                    intent={errors.phoneNumber && "danger"}
                />
            </FormGroup>
            <div className="bp3-dialog-footer-actions">
                <Button
                    type={"submit"}
                    intent={Intent.PRIMARY}
                    text={"Modifier"}
                    disabled={errors.phoneNumber}
                />
            </div>
        </form>
    </Dialog>
}