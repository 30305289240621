import React, { Component } from 'react';
import { connect } from 'react-redux';

export default function(ChildComponent) {

    class RequireAuth extends Component {

        state = {};

        constructor(props) {
            super(props);
            if (!props.loggingIn)
                props.history.push('/logout');
        }

        static getDerivedStateFromProps(nextProps) {
            if (!nextProps.loggingIn) nextProps.history.push('/logout');
            return null;
        }

        render() {
            return this.props.loggingIn && <ChildComponent/>;
        }
    }

    const mapStateToProps = ({ auth }) => ({ loggingIn: auth.loggingIn });

    return connect(mapStateToProps)(RequireAuth);
}