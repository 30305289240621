export const GUI_ACTIONS = {
    CHANGE_EXTRA_TOOLBAR: "UI_CHANGE_EXTRA_TOOLBAR",
    CLEAN_EXTRA_TOOLBAR: "UI_CLEAN_EXTRA_TOOLBAR",

    TOGGLE_COMPOSER_TAB: "UI_TOGGLE_COMPOSER_TAB",
    TOGGLE_FILTER_CONTACTS: "UI_TOGGLE_FILTER_CONTACTS",
};

export function changeExtraToolbar(element) {
    cleanExtraToolbar();
    return {type: GUI_ACTIONS.CHANGE_EXTRA_TOOLBAR, payload: element};
}

export function cleanExtraToolbar() {
    return {type: GUI_ACTIONS.CLEAN_EXTRA_TOOLBAR};
}

export function toggleFilterContacts() {
    return {type: GUI_ACTIONS.TOGGLE_FILTER_CONTACTS};
}

export function toggleComposerTab() {
    return {type: GUI_ACTIONS.TOGGLE_COMPOSER_TAB};
}
