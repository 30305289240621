import React from "react";
import {Checkbox, Icon, Intent, Tooltip} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useDispatch} from "react-redux";
import {toggle, update} from "../../redux/actions/contact.actions";
import EditableCell from "./EditableCell";

export default function ContactTableRow({contact}) {

    const dispatch = useDispatch();

    const handleOnChange = () => dispatch(toggle(contact));

    const handleSubmit = data => dispatch(update(data));

    return <tr className={`${contact.error ? "error" : ""}`}>
        {
            contact.error
                ? <td>
                    <Tooltip content="Numéro de téléphone invalide !" intent={Intent.DANGER}>
                        <Icon icon={IconNames.PHONE} intent={Intent.DANGER}/>
                    </Tooltip>
                </td>
                : <td className={"checkbox-cell"}>
                    <div>
                        <Checkbox checked={contact.selected} onChange={handleOnChange} itent={Intent.PRIMARY}/>
                    </div>
                </td>
        }
        <td className={`${contact.error ? "error" : ""}`}>
            {
                contact.error
                    ? <EditableCell contact={contact} submitAction={handleSubmit}>
                        {contact.phoneNumber}
                    </EditableCell>
                    : <div>{contact.phoneNumber}</div>
            }
        </td>
        <td>
            <div>{contact.lastName}</div>
        </td>
        <td>
            <div>{contact.firstName}</div>
        </td>
        <td>
            <div>{contact.society}</div>
        </td>
        <td>
            <div>{contact.field1}</div>
        </td>
        <td>
            <div>{contact.field2}</div>
        </td>
        <td>
            <div>{contact.field3}</div>
        </td>
        <td>
            <div>{contact.field4}</div>
        </td>
        <td>
            <div>{contact.field5}</div>
        </td>
        <td className={"checkbox-cell"}>
            <div>{contact.message}</div>
        </td>
    </tr>;
}

