import Axios from 'axios';
import { APP } from '../../../config';

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Language': `${APP.LANGUAGE}`,
    },
};

const AuthService = {
    login, logout, init,
};


function login(username, password) {
    return Axios.post(`${APP.END_POINT}/login`, {
        username,
        password,
    }, config);
}

function logout() {
}

// TODO
function init(store, history) {
    // Axios.interceptors.response.use(res => res,
    //     error => {
    //         if (error.status === 401 || error.status === 'error') {
    //             // store.dispatch(notify({ titre: 'Serveur', message: statusText, status, position: 'br' }));
    //             history.push('logout');
    //         }
    //         // store.dispatch(notify({ titre: 'Serveur', message: statusText, status }));
    //         return Promise.reject(error);
    //     });
}

export default AuthService;