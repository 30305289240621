import {ACCOUNT_ACTIONS} from "../actions/account.actions";
import {CONTACT_ACTIONS} from "../actions/contact.actions";
import {MESSAGE_ACTIONS} from "../actions/composer.actions";

const initialState = {
    loading: false,
    current: undefined,
    items: [],
    totalItems: 0
};

export const composer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_ACTIONS.SELECT_REQUEST:
        case CONTACT_ACTIONS.CLEAR_REQUEST:
        case MESSAGE_ACTIONS.SUBMIT_SUCCESS:
            return initialState;
        case MESSAGE_ACTIONS.SUBMIT_REQUEST:
        case MESSAGE_ACTIONS.FIND_ALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case MESSAGE_ACTIONS.SUBMIT_FAILURE:
            return {
                ...state,
                loading: false
            };
        case MESSAGE_ACTIONS.FIND_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.items,
                totalItems: action.payload.totalItems
            };
        case MESSAGE_ACTIONS.FIND_ALL_FAILURE:
            return {
                ...state,
                loading: false,
                items: []
            };
        case MESSAGE_ACTIONS.CREATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case MESSAGE_ACTIONS.CREATE_FAILURE:
        case MESSAGE_ACTIONS.CREATE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case MESSAGE_ACTIONS.SELECT_SUCCESS:
            return {
                ...state,
                current: action.payload
            };
        default:
            return state;
    }
};