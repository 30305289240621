import {applyMiddleware, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {rootReducer} from "./reducers/base.reducer";

const loggerMiddleware = createLogger({
    collapsed: true,
    diff: true,
});

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(
            thunkMiddleware,
            loggerMiddleware,
        ),
    ),
);

export default store;
	