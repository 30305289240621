import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {paginate} from "../../redux/actions/log.actions";
import Loader from "../core/Loader";
import {Card, HTMLTable, Icon, NonIdealState} from "@blueprintjs/core";
import LogTableRow from "./LogTableRow";
import LogDetails from "./LogDetails";
import Pagination from "../core/Pagination";
import {IconNames} from "@blueprintjs/icons";
import {Fade} from "react-reveal";

LogTable.propTypes = {};

const PAGE_LIMIT = 20;

export default function LogTable() {

    const account = useSelector(({accounts}) => accounts.current);
    const {loading, items, current, totalItems} = useSelector(({logs}) => logs);

    const dispatch = useDispatch();

    // Au montage, appel de la première page.
    useEffect(() => dispatch(paginate(account.id, 1, PAGE_LIMIT)), []);

    if (totalItems === 0) return <NonIdealState
        className={"mt64"}
        icon={IconNames.NOTIFICATIONS}
        title={"Aucun élément trouvé !"}/>;

    // Sélection d'un log
    if (current) return <LogDetails log={current}/>;

    const handlePageChanged = data => {
        const {currentPage, pageLimit} = data;
        dispatch(paginate(account.id, currentPage, pageLimit));
    };

    return <Fade big>
    <Card elevation={2}>
        <HTMLTable bordered interactive striped id={"contact-tab"} small>
            <thead>
            <tr>
                <th>Date de création</th>
                <th>Nom du fichier</th>
                <th>Titre de la composition</th>
                <th>Crée par</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {items.map(log => <LogTableRow key={log.id} log={log}/>)}
            </tbody>
        </HTMLTable>
        {loading && <Loader text={"Chargement ..."}/>}
        <Pagination totalRecords={totalItems} onPageChanged={handlePageChanged} pageLimit={PAGE_LIMIT}
                    leftIcon={<Icon icon={IconNames.CHEVRON_LEFT}/>}
                    rightIcon={<Icon icon={IconNames.CHEVRON_RIGHT}/>}
        />
    </Card>
    </Fade>;
}