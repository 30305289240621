export const ACCOUNT_ACTIONS = {
    LEAVE_REQUEST: 'ACCOUNT_LEAVE_REQUEST',

    SELECT_REQUEST: 'ACCOUNT_SELECT_REQUEST',
    SELECT_SUCCESS: 'ACCOUNT_SELECT_SUCCESS',
    SELECT_FAILURE: 'ACCOUNT_SELECT_FAILURE',

    FIND_ALL_REQUEST: 'ACCOUNT_FIND_ALL_REQUEST',
    FIND_ALL_SUCCESS: 'ACCOUNT_FIND_ALL_SUCCESS',
    FIND_ALL_FAILURE: 'ACCOUNT_FIND_ALL_FAILURE',
};

export const findAll = username => dispatch => {
    dispatch({type: ACCOUNT_ACTIONS.FIND_ALL_REQUEST});
    // TODO
    dispatch({
        type: ACCOUNT_ACTIONS.FIND_ALL_SUCCESS, payload: [
            {
                'createdAt': '2020-02-20T16:23:31.064',
                'createdBy': 'DEV',
                'updatedAt': '2020-02-20T16:23:31.064',
                'updatedBy': 'DEV',
                'id': 1,
                'label': 'KRixe ASBL',
                'serviceName': 'sms-cs83470-1',
                'enabled': true,
                'creditLeft': 405.6
            },
            {
                'createdAt': '2020-02-20T16:23:31.064',
                'createdBy': 'DEV',
                'updatedAt': '2020-02-20T16:23:31.064',
                'updatedBy': 'DEV',
                'id': 2,
                'label': 'Notresyndic.be SPRL',
                'serviceName': 'sms-cs83470-2',
                'enabled': false,
                'creditLeft': 0.0
            }
        ]
    });
    // accountService.findAll(username)
    //     .then(res => dispatch({type: ACCOUNT_ACTIONS.FIND_ALL_SUCCESS, payload: res.data}))
    //     .catch(error => {
    //         dispatch({type: ACCOUNT_ACTIONS.FIND_ALL_FAILURE});
    //         dispatch(addToast({
    //             icon: 'error',
    //             intent: 'danger',
    //             message: 'Impossible de récupérer la liste des comptes SMS associés à votre compte utilisateur.'
    //         }));
    //     });
};

export const select = account => dispatch => {
    dispatch({type: ACCOUNT_ACTIONS.SELECT_REQUEST});
    dispatch({type: ACCOUNT_ACTIONS.SELECT_SUCCESS, payload: account});
};

export const leave = () => dispatch => {
    dispatch({type: ACCOUNT_ACTIONS.LEAVE_REQUEST});
};