import AuthService from '../services/auth.service';
import {APP} from '../../../config';
import {addToast} from '../../../base/redux/actions/toaster.actions';

export const AUTH_ACTIONS = {
    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'AUTH_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTH_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTH_LOGOUT_FAILURE',
};

export function login({username, password}, history) {
    return dispatch => {
        dispatch({type: AUTH_ACTIONS.LOGIN_REQUEST});
        AuthService.login(username, password)
            .then(res => {
                // login successful if there's a jwt token in the response
                if (res.data.token) {
                    localStorage.setItem(APP.STORAGE_USR, JSON.stringify(res.data, null, 2));
                    dispatch({type: AUTH_ACTIONS.LOGIN_SUCCESS, payload: res.data});
                    history.push('/');
                }
            })
            .catch(error => {
                dispatch({type: AUTH_ACTIONS.LOGIN_FAILURE});
                dispatch(addToast({
                    icon: 'error',
                    intent: 'danger',
                    message: !error.response ? 'Le serveur de connexion est injoignable.' : 'Connexion échouée. Vérifier votre login ou votre mot de passe.'
                }));
            });
    };
}

export function logout(history) {
    return dispatch => {
        dispatch({type: AUTH_ACTIONS.LOGOUT_REQUEST});
        if (localStorage.getItem(APP.STORAGE_USR) !== null) localStorage.removeItem(APP.STORAGE_USR);
        dispatch({type: AUTH_ACTIONS.LOGOUT_SUCCESS});
        history.push('/login');
    };
}