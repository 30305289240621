import {CONTACT_ACTIONS} from "../actions/contact.actions";
import {FILE_ACTIONS} from "../actions/file.actions";

const initialState = {
    uField1: [],
    uField2: [],
    uField3: [],
    uField4: [],
    uField5: []
};

export const contactFilter = (state = initialState, action) => {
    switch (action.type) {
        case CONTACT_ACTIONS.FIND_FILTERS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

// function getUniqueValues(contacts, key) {
//     let distinct = [];
//     contacts.forEach(c => {
//         if (!distinct.find(d => c[key] === d.value)) {
//             distinct.push({value: c[key], label: c[key]});
//         }
//     });
//     distinct.sort((a, b) => {
//         if (a.value > b.value) return 1;
//         else if (a.value < b.value) return -1;
//         return 0;
//     });
//     return distinct;
// }