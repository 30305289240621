import {combineReducers} from "redux";
import {gui} from "./gui.reducer";
import {auth} from "../../../auth/redux/reducers/auth.reducer";
import {toaster} from "./toaster.reducer";
import {AUTH_ACTIONS} from "../../../auth/redux/actions/auth.actions";
import {user} from "./user.reducer";
import {composer} from "./composer.reducer";
import {fileUploader} from "./file-uploader.reducer";
import {contactFilter} from "./contact-filter.reducer";
import {accounts} from "./accounts.reducer";
import {contacts} from "./contacts.reducer";
import {tags} from "./tags.reducer";
import {logs} from "./logs.reducer";

const appReducer = combineReducers({
    accounts,
    auth,
    composer,
    contactFilter,
    contacts,
    fileUploader,
    gui,
    logs,
    tags,
    toaster,
    user,
});

export const rootReducer = (state, action) => {
    if (action.type === AUTH_ACTIONS.LOGOUT_REQUEST) return appReducer(undefined, action);
    return appReducer(state, action);
};