import React from 'react';
import {useDispatch} from "react-redux";
import {openEditPhoneDialog} from "../../redux/actions/contact.actions";

EditableCell.propTypes = {};

export default function EditableCell({children, contact, submitAction}) {

    const dispatch = useDispatch();

    const handleToggle = event => {
        event.preventDefault();
        dispatch(openEditPhoneDialog(contact, submitAction));
    }

    return <div onClick={handleToggle}>
        {children}
    </div>;
}