export default class Trilean {

    static get TRUE () { return 1 };
    static get FALSE () { return -1 };
    static get NONE () { return 0 };

    constructor(value) {
        this._value = value;
    }

    get value() {
        return this._value;
    }

    isValid() {
        return this._value === Trilean.TRUE
    }

    inverse() {
        this._value = this._value !== Trilean.TRUE ? Trilean.TRUE : Trilean.FALSE;
        return this;
    }
}
