import {addToast} from "./toaster.actions";
import {logService} from "../services/log.service";

export const LOG_ACTIONS = {
    FIND_ALL_FAILURE: "LOG_FIND_ALL_FAILURE",
    FIND_ALL_SUCCESS: "LOG_FIND_ALL_SUCCESS",
    FIND_ALL_REQUEST: "LOG_FIND_ALL_REQUEST",

    FIND_FAILURE: "LOG_FIND_FAILURE",
    FIND_SUCCESS: "LOG_FIND_SUCCESS",
    FIND_REQUEST: "LOG_FIND_REQUEST",

    BACK_REQUEST: "LOG_BACK_REQUEST",
};

export const findAll = accountId => dispatch => {
    dispatch({type: LOG_ACTIONS.FIND_ALL_REQUEST});
    logService.findAll(accountId)
        .then(res => dispatch({type: LOG_ACTIONS.FIND_ALL_SUCCESS, payload: res.data}))
        .catch(err => {
            dispatch({type: LOG_ACTIONS.FIND_ALL_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        })
};

export const paginate = (accountId, page, size) => dispatch => {
    dispatch({type: LOG_ACTIONS.FIND_ALL_REQUEST});
    logService.paginate(accountId, page, size)
        .then(res => dispatch({type: LOG_ACTIONS.FIND_ALL_SUCCESS, payload: res.data}))
        .catch(err => {
            dispatch({type: LOG_ACTIONS.FIND_ALL_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        })
};

export const find = log => dispatch => {
    dispatch({type: LOG_ACTIONS.FIND_REQUEST});
    logService.find(log.id)
        .then(res => dispatch({
            type: LOG_ACTIONS.FIND_SUCCESS, payload: {
                log,
                content: res.data
            }
        }))
        .catch(err => {
            dispatch({type: LOG_ACTIONS.FIND_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        })
};

export const back = () => dispatch =>  dispatch({type: LOG_ACTIONS.BACK_REQUEST });