import Axios from "axios";
import {APP} from "../../../config";
import {config} from "./base.service";

export const contactService = {
    paginate, update, count, findFilters
};

function paginate({accountId, pagination: {page, pageLimit, sort: {field, direction}, filters, search}}) {
    const sortParam = field ? `&sort=${field}&direction=${direction}` : "";
    let filterParam = "", searchParam = "";

    if (filters) for (const property in filters) {
        const values = filters[property].map(f => f.value).join(";");
        if (values.length > 0) filterParam = filterParam.concat(`&filters=${property}:${values}`);
    }

    if (search) for (const property in search)
        searchParam = filterParam.concat(`&search=${property}:${search[property]}`);

    return Axios.get(`${APP.END_POINT}/contact/${accountId}?page=${page}&pageLimit=${pageLimit}${sortParam}${filterParam}${searchParam}`, config());
}

function count(accountId) {
    return Axios.get(`${APP.END_POINT}/contact/${accountId}/count`, config());
}

function update(contact) {
    return Axios.put(`${APP.END_POINT}/contact/${contact.id}`, contact, config());
}

function findFilters(accountId) {
    return Axios.get(`${APP.END_POINT}/filter/contact/${accountId}`, config());
}