import React, {useEffect} from "react";
import {Callout, Card, Intent} from "@blueprintjs/core";
import AccountListItem from "./AccountListItem";
import {useDispatch, useSelector} from "react-redux";
import {findAll} from "../../redux/actions/account.actions";
import Loader from "../core/Loader";
import {Fade} from "react-reveal";

export default function AccountList() {

    const username = useSelector(({auth}) => auth.username);
    const accounts = useSelector(({accounts}) => accounts);

    const dispatch = useDispatch();

    useEffect(() => dispatch(findAll(username)), [dispatch, username]);

    return <div className={"flex align-center column"}>
        <Fade big>
            <div id={"account-switcher"}>
                <h1>Choix du compte SMS</h1>
                <Card elevation={2} id={"account-card"}>
                    <Callout intent={Intent.PRIMARY}>
                        <strong>Informations</strong>
                        <p>Liste des comptes SMS qui vont sous associés.</p>
                    </Callout>
                    <div className="account-list">
                        {accounts.loading && <Loader text={"Chargement en cours..."}/>}
                        {accounts.items.map(acc => <AccountListItem account={acc} key={acc.id}/>)}
                    </div>
                </Card>
            </div>
        </Fade>
    </div>;
};