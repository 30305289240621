import React, {useEffect, useState} from 'react';
import {Label} from "@blueprintjs/core";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {paginate} from "../../redux/actions/contact.actions";
import PropTypes from "prop-types";
import {ObjectUtil} from "@jetam/core";

SelectFilter.propTypes = {
    items: PropTypes.array,
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default function SelectFilter({items, fieldName, label}) {

    const {pagination} = useSelector(({contacts}) => contacts);
    const accountId = useSelector(({accounts}) => accounts.current.id);

    const [itemsSelected, setItemsSelected] = useState(undefined);

    const dispatch = useDispatch();

    const { filters } = pagination;

    useEffect(() => {
        if (ObjectUtil.isEmpty(filters)) setItemsSelected([]);
        else if (!ObjectUtil.isEmpty(filters[fieldName])) setItemsSelected(filters[fieldName]);
    }, [pagination]);

    const handleChange = options => {
        setItemsSelected(options);
        if (!options) delete filters[fieldName];
        else filters[fieldName] = options;
        dispatch(paginate({
            accountId,
            pagination: {
                ...pagination,
                filters
            }
        }));
    };

    return <div className="flex filter-wrapper column">
        <Label>Colonne {label}</Label>
        <Select isMulti
                isSearchable
                placeholder={"- Sélectionner une ou plusieurs options -"}
                name={fieldName}
                closeMenuOnSelect={false}
                className="basic-multi-select"
                value={itemsSelected}
                onChange={handleChange}
                options={items}
        />
    </div>;
}
