import React from "react";
import {Button, Callout, Card, FileInput, Icon, Intent, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useDispatch, useSelector} from "react-redux";
import {upload} from "../../redux/actions/file.actions";
import Loader from "../core/Loader";
import {Fade} from "react-reveal";

export default function ContactUploader() {

    const dispatch = useDispatch();

    // region Selectors
    const loading = useSelector(({fileUploader}) => fileUploader.loading);
    const token = useSelector(({auth}) => auth.token);
    const accountId = useSelector(({accounts}) => accounts.current.id);
    // endregion

    // region Events
    const handleFileInput = event =>
        dispatch(upload(event.target.files[0], token, accountId));
    // endregion

    // region Render
    const renderUpload = () => <NonIdealState className={"state"}
                                              icon={<Icon icon={IconNames.UPLOAD} iconSize={128}/>}
                                              action={<Loader text={"Upload du fichier"}/>}/>;

    const renderInput = () => <>
        <NonIdealState
            className={"state"}
            icon={<Icon icon={IconNames.IMPORT} iconSize={128}/>}
            title={"Importer le fichier de données (.xlsx)"}
            action={<FileInput text="Choisir le fichier de données..."
                               buttonText={"Parcourir"}
                               onInputChange={handleFileInput}/>}/>
        <hr/>
        <div className="flex row-reverse">
            <Button text={"Télécharger le template"} minimal intent={Intent.PRIMARY}/>
        </div>
    </>;

    return <div className={"flex align-center column"}>
        <Fade big>
            <div id={"data-uploader"}>
                <h1>Contacts</h1>
                <Card elevation={2} id={"account-card"}>
                    <Callout intent={Intent.PRIMARY}>
                        <strong>Informations</strong>
                        <p>Veuillez à respecter l'agencement des données du fichier .xslx.</p>
                    </Callout>
                    {loading ? renderUpload() : renderInput()}
                </Card>
            </div>
        </Fade>
    </div>;
    // endregion
}