import {LOG_ACTIONS} from "../actions/log.actions";

const initialState = {
    loading: false,
    items: [],
    current: undefined,
    totalItems: 0
};

export const logs = (state = initialState, action) => {
    switch (action.type) {
        case LOG_ACTIONS.FIND_ALL_REQUEST :
            return {
                ...state,
                loading: true
            };
        case LOG_ACTIONS.FIND_REQUEST :
            return {
                ...state,
                loading: true,
                current: undefined
            };
        case LOG_ACTIONS.FIND_ALL_FAILURE :
        case LOG_ACTIONS.FIND_FAILURE :
            return {
                ...state,
                loading: false,
            };
        case LOG_ACTIONS.FIND_ALL_SUCCESS: {
            const {logs: items, totalItems} = action.payload;
            return {
                ...state,
                loading: false,
                items,
                totalItems
            };
        }
        case LOG_ACTIONS.FIND_SUCCESS:
            return {
                ...state,
                loading: false,
                current: action.payload
            };
        case LOG_ACTIONS.BACK_REQUEST:
            return {
                ...state,
                current: undefined
            };
        default:
            return state;
    }
};