import React from 'react';
import {Button, Intent, Tag} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useDispatch} from "react-redux";
import {find} from "../../redux/actions/log.actions";
import PropTypes from "prop-types";

LogTableRow.propTypes = {
    log: PropTypes.object.isRequired
};

function LogTableRow({ log }) {

    const dispatch = useDispatch();

    const handleClick = event => {
        event.preventDefault();
        dispatch(find(log));
    };

    return <tr onClick={handleClick}>
        <td>{ log.createdAt }</td>
        <td>{ log.filename }</td>
        <td>{ log.subject }</td>
        <td><Tag intent={Intent.PRIMARY}>{log.user.username.toUpperCase()}</Tag></td>
        <td><Button icon={IconNames.DOCUMENT_OPEN} intent={Intent.PRIMARY} minimal onClick={handleClick}/></td>
    </tr>;
}

export default LogTableRow;