import {addToast} from "./toaster.actions";
import {fileService} from "../services/file.service";

export const FILE_ACTIONS = {
    FILE_UPLOAD_REQUEST: "FILE_UPLOAD_REQUEST",
    FILE_UPLOAD_SUCCESS: "FILE_UPLOAD_SUCCESS",
    FILE_UPLOAD_FAILURE: "FILE_UPLOAD_FAILURE",
};

export const upload = (file, token, accountId) => dispatch => {
    dispatch({type: FILE_ACTIONS.FILE_UPLOAD_REQUEST});
    fileService.upload(file, token, accountId)
        .then(res => dispatch({type: FILE_ACTIONS.FILE_UPLOAD_SUCCESS, payload: res.data}))
        .catch(err => {
            console.log(err.response)
            dispatch({type: FILE_ACTIONS.FILE_UPLOAD_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};