import {FILE_ACTIONS} from "../actions/file.actions";
import {ACCOUNT_ACTIONS} from "../actions/account.actions";
import {CONTACT_ACTIONS} from "../actions/contact.actions";
import Trilean from "@jetam/core/src/Trilean";

/*
    Problèmatique :
    Par défaut, la checkbox qui permet de sélectionner tout les éléments vaut 1.
    Dans le cas d'une désélection, la checkbox passe à 0 pour indiquer qu'il y a un ou plusieurs éléments désélectionner mais qu'il y a au moins 1 élément qui lui est sélectionné.
    Dans le cas où l'ensemble des éléments sont désélectionnés la liste vaut -1.
    Lorsque le mode vaut 1 - Tout les items étaient sélectionnés par défaut et qu'un élément est décoché, celui-ci rejoint une liste d'exclusion.
    Lorsque le mode vaut 0 - Tout les items étaient désélectionnés par défaut et qu'un élément est coché, celui-c rejoint une liste d'inclusion.
    Il est nécessaire que les listes soient resets lorsque le mode change.
    Le mode doit être envoyé lors de l'envoie du message avec soit la liste d'inclusion ou d'exclusion.
 */

const Mode = {
    INCLUDE: "INCLUDE",
    EXCLUDE: "EXCLUDE"
};

const initialState = {
    // select
    checkbox: 1,
    mode: Mode.EXCLUDE,
    selectedItemsCount: 0,
    includedItems: [],
    excludedItems: [], //
    // items
    items: [],
    totalItems: 0,
    // info
    updatedAt: undefined,
    // paginate
    pagination: {
        page: 0,
        pageLimit: 20,
        sort: {field: undefined, direction: undefined},
        filters: {},
        search: {},
    },
    loading: false,
    // edit phone
    isPhoneDialogOpened: false,
    contactSelected: undefined,
    updateAction: undefined,
};

function toggleSingle(state, action) {
    const list = state.mode === Mode.EXCLUDE ? state.excludedItems.slice() : state.includedItems.slice();
    const items = state.items.slice();

    const found = items.find(contact => contact.id === action.payload.id);
    if (found) {
        found.selected = !found.selected;
        if (list.includes(found)) {
            list.splice(list.findIndex(lc => lc.id === found.id), 1);
        } else {
            list.push(found);
        }
    }
    let checkbox;
    if (state.mode === Mode.EXCLUDE)
        checkbox = state.totalItems - list.length === state.totalItems ? 1 : 0;
    else
        checkbox = list.length === 0 ? -1 : 0
    return {
        ...state,
        checkbox,
        items,
        excludedItems: state.mode === Mode.EXCLUDE ? list : [],
        includedItems: state.mode === Mode.INCLUDE ? list : [],
        recipientsCount: state.mode === Mode.EXCLUDE ? state.totalItems - list.length : list.length,
    };
}

function toggleAll(state, action) {
    const checkbox = new Trilean(state.checkbox).inverse();
    const items = state.items.map(c => {
        c.selected = checkbox.isValid()
        return c;
    });
    return {
        ...state,
        checkbox: checkbox.value,
        mode: checkbox.value === -1 ? Mode.INCLUDE : Mode.EXCLUDE,
        items,
        excludedItems: [],
        recipientsCount: checkbox.isValid() ? state.totalItems : 0
    };
}

function paginate(state, action) {
    const {contacts, updatedAt, totalItems, page, pageLimit, sort, direction} = action.payload;
    const items = contacts.map(contact => {
        if (state.mode === Mode.EXCLUDE && state.excludedItems.find(ex => ex.id === contact.id)) {
            contact.selected = false;
            return contact;
        }
        if (state.mode === Mode.INCLUDE && !state.includedItems.find(ex => ex.id === contact.id))
            contact.selected = false;
        return contact;
    });
    return {
        ...state,
        loading: false,
        items,
        pagination: {
            ...state.pagination,
            page,
            pageLimit,
            sort: sort ? {field: sort, direction} : {field: undefined, direction: undefined},
        },
        totalItems,
        recipientsCount: state.mode === Mode.EXCLUDE ? state.totalItems - state.excludedItems.length : state.includedItems.length,
        updatedAt
    };
}

function updateContact(state, action) {
    return {
        ...state,
        items: state.items.slice().map(c => c.id === action.payload.id ? action.payload : c),
    }
}

function closeDialogPane(state, action) {
    return {
        ...state,
        isPhoneDialogOpened: false,
        contactSelected: undefined,
        updateAction: undefined,
    }
}

function openDialogPane(state, action) {
    return {
        ...state,
        isPhoneDialogOpened: true,
        contactSelected: action.payload.contact,
        updateAction: action.payload.submitAction,
    }
}

export const contacts = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_ACTIONS.SELECT_REQUEST:
        case CONTACT_ACTIONS.CLEAR_REQUEST:
            return initialState;
        case CONTACT_ACTIONS.COUNT_REQUEST:
        case CONTACT_ACTIONS.FIND_ALL_REQUEST:
        case FILE_ACTIONS.FILE_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTACT_ACTIONS.COUNT_FAILURE:
        case CONTACT_ACTIONS.FIND_ALL_FAILURE:
        case FILE_ACTIONS.FILE_UPLOAD_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case CONTACT_ACTIONS.COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                totalItems: action.payload
            };
        case CONTACT_ACTIONS.FIND_ALL_SUCCESS:
        case FILE_ACTIONS.FILE_UPLOAD_SUCCESS:
            return paginate(state, action);
        case CONTACT_ACTIONS.TOGGLE_CONTACT:
            return toggleSingle(state, action);
        case CONTACT_ACTIONS.TOGGLE_ALL_CONTACTS:
            return toggleAll(state, action);
        case CONTACT_ACTIONS.UPDATE_SUCCESS:
            return updateContact(state, action);
        case CONTACT_ACTIONS.OPEN_PHONE_DIALOG_REQUEST:
            return openDialogPane(state, action);
        case CONTACT_ACTIONS.CLOSE_PHONE_DIALOG_REQUEST:
            return closeDialogPane(state, action);
        case CONTACT_ACTIONS.FILTER_FIELD_REQUEST: {
            if(!action.payload)
                return state;
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    filters: action.payload
                }
            }
        }
        case CONTACT_ACTIONS.SEARCH_FIELD_REQUEST: {
            if(!action.payload)
                return state;
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    search: action.payload
                }
            }
        }
        default:
            return state;
    }
};


