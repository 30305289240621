import {addToast} from "./toaster.actions";
import {tagService} from "../services/tag.service";

export const TAG_ACTIONS = {
    FIND_ALL_FAILURE: "TAG_FIND_ALL_FAILURE",
    FIND_ALL_SUCCESS: "TAG_FIND_ALL_SUCCESS",
    FIND_ALL_REQUEST: "TAG_FIND_ALL_REQUEST",

    CREATE_FAILURE: "TAG_CREATE_FAILURE",
    CREATE_SUCCESS: "TAG_CREATE_SUCCESS",
    CREATE_REQUEST: "TAG_CREATE_REQUEST",

    REMOVE_FAILURE: "TAG_REMOVE_FAILURE",
    REMOVE_SUCCESS: "TAG_REMOVE_SUCCESS",
    REMOVE_REQUEST: "TAG_REMOVE_REQUEST",

    TOGGLE_CREATE_DIALOG: "OPEN_CREATE_DIALOG"

};

export const findAll = accountId => dispatch => {
    dispatch({type: TAG_ACTIONS.FIND_ALL_REQUEST});
    tagService.findAll(accountId)
        .then(res => dispatch({type: TAG_ACTIONS.FIND_ALL_SUCCESS, payload: res.data}))
        .catch(err => {
            dispatch({type: TAG_ACTIONS.FIND_ALL_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};

export const create = tag => dispatch => {
    dispatch({type: TAG_ACTIONS.CREATE_REQUEST});
    tagService.create(tag)
        .then(res => {
            dispatch({type: TAG_ACTIONS.CREATE_SUCCESS, payload: res.data});
            dispatch({type: TAG_ACTIONS.TOGGLE_CREATE_DIALOG});
            dispatch(addToast({
                icon: "tick",
                intent: "success",
                message: "Tag ajouté avec succès !"
            }));
        })
        .catch(err => {
            dispatch({type: TAG_ACTIONS.CREATE_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};

export const remove = tag => dispatch => {
    dispatch({type: TAG_ACTIONS.REMOVE_REQUEST});
    tagService.remove(tag)
        .then(res => {
            dispatch({type: TAG_ACTIONS.REMOVE_SUCCESS, payload: tag});
            dispatch(addToast({
                icon: "tick",
                intent: "success",
                message: "Le tag a été supprimé avec succès !"
            }));
        })
        .catch(err => {
            dispatch({type: TAG_ACTIONS.REMOVE_FAILURE});
            dispatch(addToast({
                icon: "error",
                intent: "danger",
                message: err.response.data.message
            }));
        });
};

export const toggleCreateDialog = () => dispatch => {
    dispatch({type: TAG_ACTIONS.TOGGLE_CREATE_DIALOG});
}