import React, {useEffect, useRef} from 'react';
import {Position, Toaster} from '@blueprintjs/core';
import {useDispatch, useSelector} from 'react-redux';
import {clearToasts} from '../redux/actions/toaster.actions';
import * as PropTypes from 'prop-types';

AppToaster.propTypes = {
    toaster: PropTypes.any,
    toasterActions: PropTypes.any
};

export default function AppToaster() {

    const toasterRef = useRef(undefined);

    const toasterQueue = useSelector(({toaster}) => toaster);

    const dispatch = useDispatch();

    /**
     * Lorsque le state est mis à jour, demande au toaster d'afficher la liste des toasts en attente d'affichage.
     * @param prevProps
     */
    useEffect(() => {
        if (toasterQueue.length) {
            toasterQueue.forEach(toast => toasterRef.current.show(toast));
            dispatch(clearToasts());
        }
    }, [toasterQueue]);

    return <Toaster position={Position.BOTTOM_RIGHT} ref={toasterRef}/>;
}

