import {ACCOUNT_ACTIONS} from "../actions/account.actions";

const initialState = {
    loading: true,
    current: undefined,
    items: [],
};

export const accounts = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_ACTIONS.FIND_ALL_FAILURE:
        case ACCOUNT_ACTIONS.FIND_ALL_REQUEST:
            return {
                loading: true,
                items: [],
                current: undefined
            };
        case ACCOUNT_ACTIONS.FIND_ALL_SUCCESS:
            return {
                loading: false,
                items: action.payload,
                current: undefined
            };
        case ACCOUNT_ACTIONS.LEAVE_REQUEST:
        case ACCOUNT_ACTIONS.SELECT_REQUEST:
            return {
                ...state,
                current: undefined
            };
        case ACCOUNT_ACTIONS.SELECT_SUCCESS:
            return {
                ...state,
                loading: false,
                current: action.payload
            };
        default:
            return state;
    }
};