import Axios from "axios";
import { APP } from "../../../config";
import { config } from "./base.service";

export const tagService = {
    findAll, create, remove
};

function findAll(accountId) {
    return Axios.get(`${APP.END_POINT}/tag/${accountId}`, config());
}

function create(tag) {
    return Axios.post(`${APP.END_POINT}/tag`, tag, config());
}

function remove(tag) {
    return Axios.delete(`${APP.END_POINT}/tag/${tag.id}`, config());
}
