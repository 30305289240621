export default class HumanUtil {
    static toNISS = str => `${str.substr(0, 2)}.${str.substr(2, 2)}.${str.substr(4, 2)}-${str.substr(6, 3)}.${str.substr(9, 2)}`;

    static toPublicSearchBceUrl = bce => bce ? `https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?lang=fr&nummer=${bce.replace(/\./g, '')}&actionLu=Recherche` : undefined;

    static toBCE = str => str ? `${str.substr(0, 4)} ${str.substr(4, 3)} ${str.substr(7, 3)}` : undefined;

    /**
     * Transforme la chaine de caractères en acceptant uniquement les caractères pouvant former un nom ou un prénom.
     * @param value
     * @returns {string}
     */
    static toName(value) {
        value = value.replace(/[^a-zA-Z-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ ]/g, '') // Remove special chars
            .replace(/\s+/g, ' ')
            .replace(/--/g, '-')
            .replace(/ -/g, '-')
            .replace(/- /g, '');
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}
