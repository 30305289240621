import React from 'react';
import {Button, Tag} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {Intent} from "@blueprintjs/core/lib/cjs/common/intent";
import {select} from "../../redux/actions/composer.actions";
import {useDispatch} from "react-redux";

TemplateTableRow.propTypes = {};

export default function TemplateTableRow({message}) {

    const dispatch = useDispatch();

    const handleLoadMessageClick = message => dispatch(select(message));

    return <tr>
        <td>{message.title}</td>
        <td>{message.content}</td>
        <td>{message.createdAt}</td>
        <td>
            <Tag intent={Intent.PRIMARY}>{message.createdBy}</Tag>
        </td>
        <td>
            <Button icon={IconNames.DOCUMENT_OPEN} minimal intent={Intent.PRIMARY}
                    onClick={() => handleLoadMessageClick(message)}/>
        </td>
    </tr>;
}