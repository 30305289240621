import React, {useEffect} from "react";
import {Card, HTMLTable, Icon, NonIdealState} from "@blueprintjs/core";
import {useDispatch, useSelector} from "react-redux";
import {IconNames} from "@blueprintjs/icons";
import Pagination from "../core/Pagination";
import TemplateTableRow from "./TemplateTableRow";
import {paginate} from "../../redux/actions/composer.actions";
import Loader from "../core/Loader";
import {Fade} from "react-reveal";

const PAGE_LIMIT = 20;

export default function TemplateTable() {

    // region Reducers
    const {items, loading, totalItems} = useSelector(({composer}) => composer);
    const accountId = useSelector(({accounts}) => accounts.current.id);
    // endregion

    const dispatch = useDispatch();

    // region Effects
    useEffect(() => dispatch(paginate({accountId, page: 1, size: PAGE_LIMIT})), []);
    // endregion

    if (totalItems === 0) return <NonIdealState
        className={"mt64"}
        icon={IconNames.NOTIFICATIONS}
        title={"Aucun élément trouvé !"}
        description={<div>Vous pouvez ajouter un template depuis l'onglet <strong>Composer</strong>.</div>}/>;

    // region Events
    const handlePageChanged = data => {
        const {currentPage, pageLimit} = data;
        dispatch(paginate({accountId, page: currentPage, size: pageLimit}));
    };
    // endregion

    // region Render
    return <Fade big>
    <Card elevation={2}>
        <HTMLTable bordered interactive striped id={"contact-tab"} small>
            <thead>
            <tr>
                <th>Titre</th>
                <th>Contenu</th>
                <th>Date de création</th>
                <th>Créé par</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {items.map(message => <TemplateTableRow key={message.id} message={message}/>)}
            </tbody>
        </HTMLTable>
        { loading && <Loader text={"Chargement ..."}/> }
        <Pagination totalRecords={totalItems} onPageChanged={handlePageChanged} pageLimit={PAGE_LIMIT}
                    leftIcon={<Icon icon={IconNames.CHEVRON_LEFT}/>}
                    rightIcon={<Icon icon={IconNames.CHEVRON_RIGHT}/>}
        />
    </Card>
    </Fade>;
    // endregion
}