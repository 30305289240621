import {TOASTER_ACTIONS} from '../actions/toaster.actions';

export const toaster = (state = [], action) => {
    switch (action.type) {
        case TOASTER_ACTIONS.ADD_TOAST:
            return [...state, action.payload];
        case TOASTER_ACTIONS.CLEAR_TOAST:
            return [];
        default:
            return state;
    }
};