const ENVIRONMENT = {
    DEV: 'dev',
    PROD: 'prod',
};

const CURRENT_ENVIRONMENT = ENVIRONMENT.PROD;

export const APP = {
    ENV: CURRENT_ENVIRONMENT,
    END_POINT: CURRENT_ENVIRONMENT === ENVIRONMENT.PROD ? "http://sms-sender.api.notresyndic.be/api/v1" : "http://localhost:8080/api/v1",
    LANGUAGE: "fr",
    STORAGE_USR: "smsenderusr",
};