import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dialog, Intent, Tag} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import {useDispatch, useSelector} from "react-redux";
import {remove} from "../../redux/actions/tag.actions";

TagTableRow.propTypes = {
    tag: PropTypes.object.isRequired
};

export default function TagTableRow({tag}) {

    const [isDialogOpened, setIsDialogOpened] = useState(false);

    const dispatch = useDispatch();

    const handleDelete = event => {
        event.preventDefault();
        dispatch(remove(tag));
        setIsDialogOpened(false);
    };

    const renderConfirmDialog = () => <Dialog icon={IconNames.CONFIRM} title={"Confirmation"} isOpen={isDialogOpened} onClose={() => setIsDialogOpened(false)}>
        <div className={"bp3-dialog-body"}>
            <p>Confirmez-vous souhaiter la suppression du tag <code>#{tag.value}</code> ?</p>
        </div>
        <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
                <Button
                    intent={Intent.SUCCESS}
                    icon={IconNames.TICK}
                    text={"Oui"}
                    onClick={handleDelete}/>
            </div>
        </div>
    </Dialog>;

    return <tr>
        { renderConfirmDialog() }
        <td><Tag>{tag.label}</Tag></td>
        <td>{tag.value}</td>
        <td>{tag.createdAt}</td>
        <td><Tag intent={tag.createdBy === "DEV" ? Intent.DANGER : Intent.PRIMARY}>{tag.createdBy}</Tag></td>
        <td>{tag.deletable && <Button icon={IconNames.SMALL_CROSS} intent={Intent.DANGER} minimal onClick={() => setIsDialogOpened(true)}/>}</td>
    </tr>;
}